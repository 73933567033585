<template>
  <div>
    <div v-for="(line, index) in table" :key="index" class="item d-flex">
      <div class="list-item">{{ line.left }}</div>
      <div class="right">{{ line.right }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardTable',
  props: {
    table: Array,
  },
};
</script>

<style lang="scss" scoped>

  .list-item {
    font-family: "RL2-Medium", Arial;
    flex: 1 0 auto;
  }
  .right {
      flex: 0 0 auto;
      width: 50%;
    }

  .item {
    width: 100%;
    padding: 18px 0;
    border-top: 1px solid #d5d5d5;
  }

</style>
