<template>
  <el-card>
    <div v-if="object.entityType === 'club'">
      <!-- CLUB -->
      <!-- BEFORE-EXPAND -->
      <div @click.prevent="switchExpanded" class="click-expand">
        <CardArrow :class="expanded ? 'expanded' : null" />
        <div id="cardBeforeExpand" class="pr-1">
          <LogoTitleSubtitle
            :title="object.name"
            :subtitle="object.orgtree.association.name"
            :img="endpointParams.logo(object._id)"
          />
        </div>
      </div>

      <!-- AFTER-EXPAND -->
      <transition-expand>
        <div v-if="expanded" class="custom-expand">
          <CardWebsite :object="object" />
          <p>{{ object.date }}</p>
          <CardAddress :object="object" />
          <p v-if="object.finderDetails">
            {{ object.finderDetails.description || "" }}
          </p>
          <p>
            Club Contact
            <br />
            Name: {{ object.contact.name }}
            <br />
            Number:
            <a
              :href="
                object.contact.number && object.contact.number.startsWith('0')
                  ? `tel:+61${parseInt(object.contact.number.slice(1))}`
                  : `tel:+${parseInt(object.contact.number)}`
              "
            >
              {{ object.contact.number }}
            </a>
            <br />
            Email:
            <a :href="`mailto:${object.contact.email}`">{{ object.contact.email }}</a>
            <br />
            <span v-if="object.meta && object.meta.website && object.meta.website.length">
              Website:
              <a :href="object.meta.website" target="_blank">
                {{ object.meta.website }}
              </a>
            </span>
            <br />
            <span v-if="object.meta && object.meta.facebook && object.meta.facebook.length">
              Facebook:
              <a :href="getFacebookUrl(object.meta.facebook)" target="_blank">
                {{ object.meta.facebook }}
              </a>
            </span>
          </p>
          <CardTable :table="clubTable(object)" />
          <div v-if="!hideRegoButton">
            <p v-if="!this.object.regoOpen">
              Registration has closed, but please get in contact if you want to know when they’re
              open again!
            </p>
            <CardButtonGroup :object="object" @submit="submit" />
          </div>
        </div>
      </transition-expand>
    </div>

    <div v-if="object.entityType === 'program'">
      <!-- PROGRAM -->
      <!-- BEFORE-EXPAND -->
      <div @click.prevent="switchExpanded" class="click-expand">
        <CardArrow :class="expanded ? 'expanded' : null" />
        <div class="pr-1">
          <LogoTitleSubtitle :title="object.name" :img="logo" :subtitle="object.type" />
        </div>
        <p>
          {{ object.startTime }} {{ formatDate(object.startDate) }} -
          {{ formatDate(object.endDate) }}
        </p>
        <CardTable :table="programTable(object)" />
      </div>

      <transition-expand>
        <!-- AFTER-EXPAND -->
        <div v-if="expanded" class="custom-expand">
          <p class="m-0">{{ object.venue ? object.venue.name : "" }}</p>
          <CardAddress :object="object" />
          <p class="mb-0">
            {{ object.gdo.name }}
          </p>
          <p class="m-0">
            <a
              v-if="object.gdo"
              :href="
                object.gdo.number && object.gdo.number.startsWith('0')
                  ? `tel:+61${parseInt(object.gdo.number.slice(0))}`
                  : `tel:+${parseInt(object.gdo.number)}`
              "
            >
              {{ object.gdo ? object.gdo.number : "" }}
            </a>
          </p>
          <p class="m-0">
            <a :href="object.gdo ? `mailto:${object.gdo.email}` : '#'">
              {{ object.gdo ? object.gdo.email : "" }}
            </a>
          </p>
          <p class="m-0">
            <span v-if="object.meta && object.meta.website && object.meta.website.length">
              Website:
              <a :href="object.meta.website" target="_blank">
                {{ object.meta.website }}
              </a>
            </span>
            <br />
            <span v-if="object.meta && object.meta.facebook && object.meta.facebook.length">
              Facebook:
              <a :href="getFacebookUrl(object.meta.facebook)" target="_blank">
                {{ object.meta.facebook }}
              </a>
            </span>
          </p>
          <p>{{ object.notes || "" }}</p>
          <div v-if="!hideRegoButton">
            <CardButtonGroup :object="object" @submit="submit" />
          </div>
        </div>
      </transition-expand>
    </div>

    <div v-if="object.entityType === 'association'">
      <!-- ASSOCIATION -->
      <!-- BEFORE-EXPAND -->
      <div @click.prevent="switchExpanded" class="click-expand">
        <CardArrow :class="expanded ? 'expanded' : null" />
        <LogoTitleSubtitle
          :title="object.name"
          subtitle=""
          :img="endpointParams.logo(object._id)"
        />
        <!-- no subtitle -->
      </div>

      <!-- AFTER-EXPAND -->
      <transition-expand>
        <div v-if="expanded" class="custom-expand">
          <p></p>
          <p>
            Association Contact
            <br />
            Name: {{ object.contact.name }}
            <br />
            Number:
            <a
              :href="
                object.contact.number && object.contact.number.startsWith('0')
                  ? `tel:+61${parseInt(object.contact.number.slice(1))}`
                  : `tel:+${parseInt(object.contact.number)}`
              "
            >
              {{ object.contact.number }}
            </a>
            <br />
            Email:
            <a :href="`mailto:${object.contact.email}`">{{ object.contact.email }}</a>
            <br />
            <span v-if="object.meta && object.meta.website && object.meta.website.length">
              Website:
              <a :href="object.meta.website" target="_blank">
                {{ object.meta.website }}
              </a>
            </span>
            <br />
            <span v-if="object.meta && object.meta.facebook && object.meta.facebook.length">
              Facebook:
              <a :href="getFacebookUrl(object.meta.facebook)" target="_blank">
                {{ object.meta.facebook }}
              </a>
            </span>
          </p>
          <CardAddress :object="object" />
          <CardTable :table="associationTable(object)" />
          <div v-if="!hideRegoButton">
            <p v-if="!this.object.regoOpen">
              Registration has closed, but please get in contact if you want to know when they’re
              open again!
            </p>
            <CardButtonGroup :object="object" @submit="submit" />
          </div>
        </div>
      </transition-expand>
    </div>

    <div v-if="object.entityType === 'team'">
      <!-- TEAM -->
      <!-- BEFORE-EXPAND -->
      <div @click.prevent="switchExpanded" class="click-expand">
        <CardArrow :class="expanded ? 'expanded' : null" />
        <div class="pr-1">
          <LogoTitleSubtitle
            :title="object.name"
            :subtitle="`${object.competition.name}, ${object.association.name}`"
            :img="endpointParams.logo(object.association._id)"
          />
        </div>
      </div>

      <!-- AFTER-EXPAND -->
      <transition-expand>
        <div v-if="expanded" class="custom-expand">
          <p>{{ object.date }}</p>
          <p>
            {{ object.venue.name }}
            <CardAddress v-if="object.venue" :object="object" />
          </p>
          <p>Team Leader: {{ object.contact.name }}</p>
          <p>
            {{ object.association.name }}
            <br />
            Number:
            <a
              :href="
                object.association.contact.number &&
                object.association.contact.number.startsWith('0')
                  ? `tel:+61${parseInt(object.association.contact.number.slice(1))}`
                  : `tel:+${parseInt(object.association.contact.number)}`
              "
            >
              {{ object.association.contact.number }}
            </a>
            <br />
            Email:
            <a :href="`mailto:${object.association.contact.email}`">{{
              object.association.contact.email
            }}</a>
            <br />
            <span
              v-if="
                object.association &&
                object.association.meta &&
                object.association.meta.website &&
                object.association.meta.website.length
              "
            >
              Website:
              <a :href="object.association.meta.website" target="_blank">
                {{ object.association.meta.website }}
              </a>
            </span>
            <br />
            <span
              v-if="
                object.association &&
                object.association.meta &&
                object.association.meta.facebook &&
                object.association.meta.facebook.length
              "
            >
              Facebook:
              <a :href="getFacebookUrl(object.association.meta.facebook)" target="_blank">
                {{ object.association.meta.facebook }}
              </a>
            </span>
            <br />
          </p>
          <CardTable :table="teamTable(object)" />
          <div v-if="!hideRegoButton">
            <p v-if="!this.object.regoOpen || this.object.teamInManyComps">
              Registration has closed, but please get in contact if you want to know when they’re
              open again!
            </p>
            <CardButtonGroup :object="object" @submit="submit" />
          </div>
        </div>
      </transition-expand>
    </div>
  </el-card>
</template>

<script>
import moment from "moment";
import flow from "lodash/fp/flow";
import zip from "lodash/fp/zip";
import map from "lodash/fp/map";
import LogoTitleSubtitle from "@/components/LogoTitleSubtitle.vue";
import CardWebsite from "@/components/card/components/CardWebsite.vue";
import CardAddress from "@/components/card/components/CardAddress.vue";
import CardTable from "@/components/card/components/CardTable.vue";
import CardButtonGroup from "@/components/card/components/CardButtonGroup.vue";
import CardArrow from "@/components/card/components/CardArrow.vue";
import TransitionExpand from "@/components/presentation/TransitionExpand.vue";
import { endpointParams } from "@/utils/constants/api";
import { formatMoney } from "@/utils/money";

export default {
  name: "Card",
  components: {
    TransitionExpand,
    CardArrow,
    CardButtonGroup,
    CardTable,
    CardAddress,
    CardWebsite,
    LogoTitleSubtitle,
  },
  props: {
    object: Object,
    hideRegoButton: Boolean,
  },
  data() {
    return {
      expanded: false,
      endpointParams,
    };
  },
  methods: {
    getFacebookUrl(url) {
      return `https://facebook.com/${url}`;
    },
    switchExpanded() {
      this.expanded = !this.expanded;
    },
    formatDate(timestamp) {
      return moment(timestamp).format("dddd MMMM D");
    },
    clubTable(club) {
      const table = [];
      if (!club.finderDetails) return;
      if (club.finderDetails.contactType) {
        table.push({
          left: "Type",
          right:
            club.finderDetails.contactType === "Both"
              ? "Both Contact and Non-contact"
              : club.finderDetails.contactType,
        });
      }

      const sequence = {
        U5: 1,
        U6toU9: 2,
        U10toU12: 3,
        U13toU16: 4,
        U17toU18: 5,
        U19: 6,
      };
      const formatted = Object.entries(club.finderDetails.ages);
      const mapped = formatted.map((line) => [...line, sequence[line[0]]]);
      const sorted = mapped.sort((a, b) => a[2] - b[2]);

      sorted.forEach((entry) => {
        if (entry[1].length === 0) return;
        const copied = entry[0].replace("U19", "19+");

        const sorted2 = [...entry[1]].sort().reverse();

        table.push({
          left: copied.replace("to", " - "),
          right: sorted2.join(" and "),
        });
      });

      // eslint-disable-next-line consistent-return
      return table;
    },
    programTable(object) {
      const leftItems = [];
      const rightItems = [];

      if (
        object.settings.minAge &&
        object.settings.maxAge &&
        object.settings.minAge === object.settings.maxAge
      ) {
        leftItems.push(`${object.settings.maxAge} Year Olds`);
      } else if (object.settings.minAge && object.settings.maxAge) {
        leftItems.push(`${object.settings.minAge}-${object.settings.maxAge} Year Olds`);
      } else {
        leftItems.push("");
      }
      leftItems.push(object.settings.price ? formatMoney(object.settings.price) : "");
      object.contactType = "Non-Contact";
      if (Array.isArray(object.programsSettings)) {
        const isContact = object.programsSettings[0].isContact; // possible values true, false, undefined
        if (isContact) object.contactType = "Contact";
      } else if (object.programsSettings) {
        const isContact = object.programsSettings.isContact; // possible values true, false, undefined
        if (isContact) object.contactType = "Contact";
      }
      rightItems.push(object.contactType);
      rightItems.push(object.settings.duration || "");

      return flow(
        zip,
        map(([l, r]) => ({ left: l, right: r }))
      )(leftItems, rightItems);
    },
    associationTable(object) {
      const items = [];

      if (object.orgtree.national && object.orgtree.national._id === 31) {
        items.push({ left: "Type", right: "Touch" });
      }

      return items;
    },
    teamTable(object) {
      const items = [];

      if (object.orgtree.national && object.orgtree.national._id === 31) {
        items.push({ left: "Type", right: "Touch" });
      }

      if (object.ageLvl && object.competition && object.competition.gender) {
        items.push({
          left: object.ageLvl,
          right: object.competition.gender === "All" ? "Mixed" : object.competition.gender,
        });
      }

      return items;
    },
    submit() {
      this.$emit("submit");
    },
  },
  computed: {
    logo() {
      if (this.object && this.object.meta && this.object.meta.avatar) {
        console.log("here");
        return this.endpointParams.logo(this.object._id);
      } else return this.endpointParams.logo(this.object.settings._id);
    },
  },
};
</script>

<style lang="scss" scoped>
.pr-1 {
  padding-right: 15px;
}

.click-expand {
  position: relative;
}

.custom-expand {
  /* fix for IE this is not displaying. */
  visibility: visible !important;
  position: static !important;
}
</style>
