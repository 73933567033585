<template>
  <div class="arrow">
    <i class="icon-arrow-down" />
  </div>
</template>
<script>
export default {
  name: 'CardArrow',
  data() {
    return {
      expanded: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.arrow {
    position: absolute;
    right: 2px;
    transition: 0.5s;

  &.expanded {
    transform: rotate(-180deg);
  }
}
</style>
