<template>
  <div
      v-if="object.website"
      class="club-website d-flex"
      @click="open(object.website)"
  >
    <div class="flex-1">{{ object.type }} website</div>
    <div class="flex-0">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardWebsite',
  props: {
    object: Object,
  },
  methods: {
    open() {
      window.open('https://itsolutionstuff.com', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>

  .club-website {
    cursor: pointer;
    padding: 10px 0px;
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    border-top: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;

    i {
      font-size: 1rem;
      font-weight: 600;
    }
  }
</style>
